// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #d9d9d9;
  --ion-color-primary-rgb: 217, 217, 217;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #bfbfbf;
  --ion-color-primary-tint: #dddddd;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  --color-white: #fff;
  --color-black: #000;
  --color-grey-light: #edf1f4;
  --color-grey: #D0D1D6;
  --color-text-grey: #B0B3BD;
  --color-light-blue: #537098;
  --color-lighter-blue: #47526C;
  --color-blue-lighter: #46516D;
  --color-primary-blue: #2E5180;
  --color-medium-blue: #223d60;
  --color-blue-dark: #182648;
  --color-primary-green: #7AC2B1;
  --color-orange: #EBB428;
  --color-darker-orange: #C89922;
  --color-dark-orange: #c95b32;
  --color-menu-active: #C95B32;
  --color-yellow: #EFC376;
  --color-green: #C9D8D5;
  --color-mallow: #E8CFC1;

  /** Backgrounds **/
  --background: var(--color-blue-dark) url("/assets/apparently-bg-svg.svg") repeat fixed auto 100% !important;
  /** fonts **/
  --ion-font-family: "Open Sans", "Helvetica-Neue", "Roboto-Light", sans-serif;

  @media all and (max-width: 320px) {
    --font-small-size: 10px;
    --font-size: 11px;
    --font-medium-size: 12px;
    --font-large-size: 14px;
    --font-x-large-size: 16px;
    --font-xxl-large-size: 18px;
  }
  @media all and (min-width: 321px) and (max-width: 512px) {
    --font-small-size: 11px;
    --font-size: 12px;
    --font-medium-size: 14px;
    --font-large-size: 16px;
    --font-x-large-size: 18px;
    --font-xxl-large-size: 20px;
  }
  @media all and (min-width: 513px) {
    --font-small-size: 12px;
    --font-size: 13px;
    --font-medium-size: 14px;
    --font-large-size: 16px;
    --font-x-large-size: 18px;
    --font-xxl-large-size: 20px;
  }
}
