/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import url("https://fonts.bunny.net/css?family=open-sans:300,300i,400,400i,700,700i,800&display=swap");
@import url("https://fonts.bunny.net/css?family=lato:300,300i,400,400i,700,700i,800&display=swap");
@import url("https://fonts.bunny.net/css?family=outfit:300,300i,400,400i,700,700i,800&display=swap");

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

:root,
:root[mode=ios],
:root[mode=md]{
  --ion-font-family: "Open Sans", "Helvetica-Neue", "Roboto-Light", sans-serif;
  font-family: "Open Sans", "Helvetica-Neue", "Roboto-Light", sans-serif;
}

// FONTS
* {
  font-size: var(--font-size);
}

.font-small-size { font-size: var(--font-small-size); }
.font-size { font-size: var(--font-size); }
.font-medium-size { font-size: var(--font-medium-size); }
.font-large-size { font-size: var(--font-large-size); }
.font-x-large-size { font-size: var(--font-x-large-size); }
.font-xxl-large-size { font-size: var(--font-xxl-large-size); }

body {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Open Sans", sans-serif;
}

.font-lato {
  font-family: "Lato", sans-serif;
}

.text-bold {
  font-weight: bold;
}

.img-responsive {
  width: 100%;
  height: auto;
}

// DISPLAY and SIZE STYLES

:root {
  --ion-item-background: #fff;
  --ion-background-color: var(--color-blue-dark);
  --ion-tab-bar-background: #fff;
  --border: 0;

  // @todo its only a hotfix for the ios footer height issue
  --ion-safe-area-bottom: 0;
}

ion-header,
ion-footer,
ion-toolbar {
  --ion-background-color: transparent;
}

ion-header {
  --ion-toolbar-background: transparent;
  background:      var(--color-blue-dark) url("/assets/apparently-bg-svg.svg");
  background-size: auto 100vh;
}

// @todo if the ion-toolbar will be introduced it could be useful...
/*ion-footer ion-toolbar {
  &::ng-deep .toolbar-container,
  .toolbar-container {
    overflow: auto;
  }
}*/

#main-content {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-content: stretch;
	flex-wrap: wrap;
}

.background {
  background-size: auto 100%;
  background:      var(--color-blue-dark) url("/assets/apparently-bg-svg.svg") repeat fixed;
}

.background-color {
  background-color: #e6cec0;

  min-height: calc(100vh + var(--offset-top));
	margin-top: calc(-1* var(--offset-top));

  padding-top: var(--offset-top);
}

.page-content {
  padding: 30px;
  max-width: 576px;
  margin: 0 auto;

  position: relative;
}

.top-title {
  margin-left: 10px;
}

.modal {
  background-color: white;
  width: 100%;
  height: auto;
  display: block;
  margin: 5% auto 0;
  border-radius: 20px;
  padding: 20px;
  &.transparent {
    background-color: transparent;
  }
  &.full-page-modal {
    margin-top: 25%;
  }

  &__item {
    &__header {}

    &__content {
      font-size: var(--font-medium-size);

      > * {
        margin-top: 0;
      }
    }
  }
}

$sizes: (4, 8, 10, 12, 14, 16);
@each $size in $sizes {
  .br-#{$size} {
    border-radius: #{$size}px;
  }
}

// DISPLAY and SIZE STYLES

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.space-between {
  align-items: center;
  justify-content: space-between;
}

.center {
  margin: 0 auto;
}
.error-messages {
  color: darkred;
}

$sizes: (12, 14, 15, 16, 18, 20, 22, 24, 28, 30, 32, 36, 40, 44, 48, 80, 88);
@each $size in $sizes {
  .s-#{$size} {
    width: #{$size}px !important;
    height: #{$size}px !important;
  }
}
//SIZE STYLES
$sizes: (4, 5, 8, 10, 12, 13, 16, 20, 24, 28, 32, 40, 60, 80);
@each $size in $sizes {
  .gap-#{$size} {
    display: flex;
    gap: #{$size}px;
  }
}

.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}

.w-20 {
  width: 80%;
}

// FONT STYLES
.text-center {
  text-align: center;
}

$sizes: (
        10,
        11,
        12,
        14,
        15,
        16,
        18,
        20,
        22,
        24,
        28,
        32,
        36,
        40,
        44,
        48,
        80,
        88
);
@each $size in $sizes {
  .f-#{$size} {
    font-size: #{$size}px !important;
  }
}

// mt-0: margin-top: 0
// pr-40: padding-right: 40px
// pb-36: padding-bottom: 36px
// ml-12: margin-left: 12px
// mx-8: margin-left: 8px; margin-right: 8px;
// py-8: padding-top: 8px; padding-bottom: 8px;

$models: (
        "m": "margin",
        "p": "padding",
);
$directions: (
        "t": "top",
        "r": "right",
        "b": "bottom",
        "l": "left",
);
$sizes: (0, 2, 4, 5, 8, 10, 12, 16, 20, 24, 25, 28, 32, 36, 40, 60, 75);
@each $size in $sizes {
  @each $model in $models {
    @each $direction in $directions {
      .#{nth($model,1)}#{nth($direction,1)}-#{$size} {
        #{nth($model,2)}-#{nth($direction,2)}: #{$size}px !important;
      }
    }

    .#{nth($model,1)}-#{$size} {
      #{nth($model,2)}: #{$size}px !important;
    }
    .#{nth($model,1)}y-#{$size} {
      #{nth($model,2)}-top: #{$size}px !important;
      #{nth($model,2)}-bottom: #{$size}px !important;
    }
    .#{nth($model,1)}x-#{$size} {
      #{nth($model,2)}-left: #{$size}px !important;
      #{nth($model,2)}-right: #{$size}px !important;
    }
  }
}

.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

//COLORS

$colors: (
        "black",
        "white",
        "grey",
        "light-blue",
        "primary-blue",
        "blue-dark",
        "orange",
        "yellow",
        "dark-orange",
        "primary-green",
        "mallow"
);
@each $color in $colors {
  .#{$color} {
    --my-color: var(--color-#{$color});
    color: var(--my-color) !important;
  }
  .bg-#{$color} {
    --my-bg-color: var(--color-#{$color});
    background-color: var(--my-bg-color) !important;
  }
  .bg-rgb-#{$color} {
    --my-bg-rgb-color: var(--color-#{$color});
    /*     background-color: rgba(var(--my-bg-rgb-color), 0.25);
        background: rgba(var(--my-bg-rgb-color), 0.25); */
    background-color: rgba(#C95B32, 0.25);
  }
}

// HELPERS
.clickable {
  cursor: pointer;
}

// MODAL
.modal-content {
  max-height: 77vh;
  overflow: auto;
}

// BUTTONS

.btn {
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

// SHADOWS
.shadow-basic {
  box-shadow: 10px 10px 40px 0px rgba(85, 121, 108, 0.25), 0px 4px 10px 0px rgba(85, 121, 108, 0.1);
}

::ng-deep {
  ion-header {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .footer-tabs {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

app-header {
  font-size: var(--font-large-size);
  font-weight: 700;
}

// Hide the box shadow on the header
/*.ion-page > ion-header[role="banner"],
.ion-page > .header-md {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.header-md::after {
  background-image: none !important;
}*/


.separator {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 1px;
  background: linear-gradient(to left, rgba(208, 209, 214, 0.5), rgb(208, 209, 214), rgba(208, 209, 214, 0.5));
}

ion-input.custom-input.ion-invalid.ion-dirty.ion-touched {
  border-bottom: var(--highlight-color) solid 1px;
}

.sc-ion-input-md-h:not(.legacy-input) {
  min-height: 30px;;
}

.item {
  padding: 0 10px 0 10px;
}

.input-highlight.sc-ion-input-md {
  width: calc(100% - 9px);
}

ul {
  padding-inline-start: 20px;
}

textarea {
  background: #e4e6e8;
  border: none;
  width: 100%;
  border-radius: 10px;
  padding: 6px;
  margin-top: 5px;
  font-size: var(--font-size);
  &::placeholder {
    color: #47526C;
    font-size: var(--font-size);
    font-style: italic;
  }
}
.desc *, .swipe-content-desc-list-list-elements * {
  font-size: calc(var(--font-size) + 1px) !important;
}
.footer-tabs ion-tab-bar {
  //position: relative;
  //top: env(safe-area-inset-bottom);
}
ion-header {
  //padding-top: env(safe-area-inset-top);
}

.mt--25 {
  margin-top: -25px;
}
.native-input {
  caret-color:  black !important;
}

.overflow-none {
  overflow: hidden;
}


/* Make the video relative, instead of absolute, so that
   the parent container will size based on the video. Also,
   note the max-height rule. Note the line-height 0 is to prevent
   a small artifact on the bottom of the video.
 */
.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
video.video-js,
video.vjs-tech {
  /* max-height: calc(100vh - 64px); */
  position: relative !important;
  /* height: 100%; */
  width: 100% !important;
  /* max-width: 100% !important; */
  padding-top: 0 !important;
  line-height: 0;
  aspect-ratio: 9 / 16;
  height: 100% !important;
  margin-top: 0;
  max-height: 699px;
  max-width: 392px;
}

/* Fullscreen mode */
.vjs-fullscreen {
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  video.video-js,
  video.vjs-tech {
    max-height: none !important;
    max-width: none !important;
  }
}


/* Fix the control bar due to us resetting the line-height on the video-js */
.vjs-control-bar {
  line-height: 1;
}

.modal.is-video {
  max-width: 392px !important;
  margin: 0 auto;
  .modal-card-body-inner {
    max-width: 392px;
  }
}

.modal-content.video-modal-content {
  max-width: 392px;
  margin: 0 auto;
  height: auto !important;
  max-height: max-content !important;
}

app-vjs-player.vjs-player {
  display: block;
  height: auto;
  max-width: 392px;
  margin: 0 auto;
}

.challenge-content {
  min-height: calc(100vh - 150px);
}

.terms {
  text-align: center;
  margin: 0;

  &__link {
    display: inline-block;

    color: var(--dark-blue);
    text-decoration: underline;

    padding: .25rem;
  }
}
